@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100&display=swap');

#contato {
    background-image: url('https://storage.codeinformatica.com.br/call-center-02-min.webp');
}

.z-index-top {
    z-index: 9999;
}